import 'lite-youtube-embed'
import 'lite-vimeo-embed/module/lite-vimeo-embed'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import dialog from '@alpinejs/ui/src/dialog'
import popover from '@alpinejs/ui/src/popover'

import 'virtual:svg-icons-register'
import.meta.glob('../img/**/*')

import SiteHeader from './components/SiteHeader'

export function init() {
  window.APP = window.APP || {}
  window.Alpine = Alpine

  Alpine.plugin(dialog)
  Alpine.plugin(popover)
  Alpine.plugin(focus)

  Alpine.data('SiteHeader', SiteHeader)

  Alpine.start()
}

window.addEventListener('DOMContentLoaded', init)

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
