export default ({
  mobileOpen = false,
  translationOpen = false,
  scrolledFromTop= false,
}) => {
  const minYOffset= 100

  return {
    mobileOpen,
    translationOpen,
    scrolledFromTop,
    handleScroll() {
      this.scrolledFromTop = window.scrollY >= minYOffset
    },
    init() {
      this.handleScroll()
      this.$watch('mobileOpen', () => {
        if (this.mobileOpen) {
          this.translationOpen = false
        }
      })
    },
  }
}
